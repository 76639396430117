@import "../../../assets/css/variables.scss";
.noresults {
	width: 100%;
	text-align: center;
	margin: 0 auto;
	padding: 20px 0;
    color: $grey-dark8;
	.subtitle {
		font-size: 20px;
		line-height: 46px;
        font-weight: 500;
	}
	p {
		font-size: 18px;
        font-weight: 400;
		margin:0 0 5px 0;
	}
}